import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'

const getFromCache = async longUrl => {
  const snapshot = await firestore
    .collection(COLLECTIONS_ENUM.SHRINKER)
    .where('longUrl', '==', longUrl)
    .get()

  return snapshot.empty ? '' : snapshot.docs[0].data().shortUrl
}

const setToCache = async ({ longUrl, shortUrl }) => {
  await firestore
    .collection(COLLECTIONS_ENUM.SHRINKER)
    .doc()
    .set({ longUrl, shortUrl })
}

export const shrinkUrl = async longUrl => {
  let shortUrl = await getFromCache(longUrl)

  if (shortUrl) {
    return shortUrl
  }

  const restPath = 'https://us-central1-shrinker-app.cloudfunctions.net/'
  const getRedirectUrl = restPath + 'generateRedirectToByRandomIds'
  const response = await fetch(getRedirectUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      number: 1,
      redirectTo: longUrl
    })
  })
  const result = await response.json()
  shortUrl = `https://trf.ai/${result[0].pathName}`

  await setToCache({ longUrl, shortUrl })

  return shortUrl
}
