<template>
  <div>
    <v-card-title>
      <v-spacer />
      {{ $t("m.landing.title") }}
      <v-spacer />
    </v-card-title>
    <v-container>
      <v-alert
        v-model="saveError"
        border="right"
        colored-border
        type="error"
        elevation="2"
        dismissible
        >Error saving landing page</v-alert
      >
    </v-container>
    <LoadingDialog v-if="loading" />
    <LandingPageGenerator
      v-if="!loading"
      :pageScriptListeners="pageScriptPreloadedListeners"
      :jobScriptListeners="jobTypePreloadedListeners"
      :jobScript="jobQuestionsScript"
      :pageScript="pageQuestionsScript"
      :onSave="onSave"
      :onPreview="onPreview"
      :jobTypes="jobTypes"
      :baseStaticSiteUrl="baseStaticSiteUrl"
      :hasPreview="hasPreview"
      :hasJobSite="hasLive"
    />
    <v-container>
      <v-expand-transition>
        <v-alert dense text type="success" v-show="recentlySaved">{{
          $t("m.landing.saved")
        }}</v-alert>
      </v-expand-transition>
    </v-container>
  </div>
</template>

<script>
import LoadingDialog from '@/components/LoadingDialog'
import jobQuestionsScript from '!raw-loader!@/upil-scripts/landing-page/job-questions.userpil' // eslint-disable-line import/no-webpack-loader-syntax
import pageQuestionsScript from '!raw-loader!@/upil-scripts/landing-page/page-questions.userpil' // eslint-disable-line import/no-webpack-loader-syntax
import LandingPageGenerator from '@/components/landingPageGenerator'
import { listeners, getJobTypes, saveLandingPageData, saveLandingPagePreviewData, loadCurrentDeployData, loadCurrentPreviewData } from './listeners'
import { mapState } from 'vuex'
import * as Sentry from '@sentry/browser'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    LandingPageGenerator,
    LoadingDialog
  },
  computed: {
    ...mapState('auth', ['uid'])
  },
  data () {
    return {
      pageScriptPreloadedListeners: null,
      jobTypePreloadedListeners: null,
      loading: true,
      jobQuestionsScript,
      pageQuestionsScript,
      jobTypes: [],
      saving: false,
      saveError: false,
      recentlySaved: false,
      baseStaticSiteUrl: process.env.VUE_APP_BASE_STATIC_SITE_URL,
      hasLive: false,
      hasPreview: false
    }
  },
  watch: {
    uid: {
      immediate: true,
      handler (uid) {
        if (uid) {
          this.setup(uid)
        }
      }
    }
  },
  methods: {
    async setup (uid) {
      this.jobTypes = await getJobTypes(uid)
      const live = await loadCurrentDeployData(uid)
      const preview = await loadCurrentPreviewData(uid)
      this.hasLive = Boolean(live)
      this.hasPreview = Boolean(preview)
      const { pageScriptPreloadedListeners, jobTypePreloadedListeners } = await listeners({ live, preview })
      this.pageScriptPreloadedListeners = pageScriptPreloadedListeners
      this.jobTypePreloadedListeners = jobTypePreloadedListeners
      this.loading = false
    },
    async onPreview ({ pageDetails, jobsData }) {
      return this.onSave({ pageDetails, jobsData, preview: true })
    },
    async onSave ({ pageDetails, jobsData, preview }) {
      try {
        this.saveError = false
        this.saving = true
        const saveFunction = preview ? saveLandingPagePreviewData : saveLandingPageData
        await saveFunction({ ownerId: this.uid, data: { pageDetails, jobsData } })
        if (preview) {
          this.hasPreview = true
        } else {
          this.hasLive = true
        }
        this.recentlySaved = true
        window.setTimeout(() => {
          if (this) {
            this.recentlySaved = false
          }
        }, 3000)
      } catch (err) {
        this.saveError = true
        console.error('Error saving landing page data', err) // eslint-disable-line no-console
        Sentry.captureException(err)
      } finally {
        this.saving = false
      }
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_LANDING_PAGE_GENERATOR)
  }
}
</script>

<style>
</style>
