import * as Sentry from '@sentry/browser'
// import debounce from 'lodash.debounce'
import once from 'lodash.once'
import { encodeCampaignInfo } from '@/helpers/campaign-info'
import { analyticsEvent, EVENT_NAMES, registerOwnerEmail, registerOwnerCompanyName } from '@/helpers/analytics'
import { userInputAnalyticsEvent, userInputValueCheck } from './shared'
import {
  // sendFinalHiringOnboardingInformation,
  addQRCode } from '@/helpers/firebase-functions'
import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'
// import scheduleryService from '@/helpers/schedulerly'
import { shrinkUrl } from '@/helpers/referral'
import { symbols } from '@appsocially/userpil-core'
import { delay } from '@/helpers/time'
window.symbols = symbols

// const sendFinalHiringOnboardingInformationDebounced = debounce(() => sendFinalHiringOnboardingInformation(), 100)

const recruitScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

export const scenarioPublicRef = uid => firestore
  .collection(COLLECTIONS_ENUM.OWNERS)
  .doc(uid)
  .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
  .doc(recruitScenarioId)

const unresolvedToNull = value => {
  return value === symbols.UNRESOLVED ? undefined : value
}
function parseUserInput ({ type, label, output }) {
  const { key, value } = output

  return parseType({ type, output: { key, value: unresolvedToNull(value) } })
  // return parseLabel({ type, label, output: fromType })
}

function userInputAnalytics ({ name, inputValue }) {
  switch (name) {
    case 'companyNameJp':
      registerOwnerCompanyName({ companyNameJp: inputValue })
      break
    case 'managerEmail':
      registerOwnerEmail({ address: inputValue })
      break
  }
}

function parseType ({ type, output }) {
  const { key, value } = output
  switch (type) {
    case 'multi-select':
      return { key, value }
    default:
      return output
  }
}

export const listeners = (uid) => {
  return {
    'external': async (payload, preventDefault) => {
      preventDefault()
      const { topic } = payload
      switch (topic) {
        case 'jobLink': {
          const absoluteUrl = window ? window.location.origin : ''
          const campaignInfo = encodeCampaignInfo({ ownerId: uid, ownerScenarioId: recruitScenarioId, campaignId: '001', campaignType: 'master' })
          const shortUrl = await shrinkUrl(`${absoluteUrl}/jobs/${uid}/${recruitScenarioId}?campaignInfo=${campaignInfo}`)

          const qrCode = {
            label: 'スタッフ募集',
            url: shortUrl,
            enabled: true,
            count: 0,
            qrCodeId: '001',
            qrCodeType: 'master'
          }
          addQRCode({ ownerId: uid, scenarioId: recruitScenarioId, qrCode })

          return shortUrl
        }
        case 'baseUrl': {
          const absoluteUrl = window ? window.location.origin : process.env.VUE_APP_BASE_URL
          return absoluteUrl
        }
        default:
          return symbols.UNRESOLVED
      }
    },
    'scenario-start': (payload, preventDefault) => {
      return new Promise(async (resolve) => {
        analyticsEvent(EVENT_NAMES.LOADED_SCENARIO)
        resolve()
      })
    },
    'scenario-end': once((payload, preventDefault) => {
      const saveRef = scenarioPublicRef(uid)
      // Make sure we don't cause the document write quota exceeded
      return delay(1000)
        .then(() => {
          return saveRef.set(
            { formCompleted: true },
            {
              merge: true
            }
          )
        })

      // return new Promise(async (resolve) => {
      //   try {
      //     await sendFinalHiringOnboardingInformationDebounced()
      //   } catch (err) {
      //     Sentry.captureException(err)
      //     // eslint-disable-next-line
      //     console.error(`Problem with 'sendFinalHiringOnboardingInformation': `, err)
      //   } finally {
      //     analyticsEvent(EVENT_NAMES.COMPLETED_SCENARIO, {
      //       scenarioId: recruitScenarioId
      //     })
      //     resolve()
      //   }
      // })
    }),
    'input-update': (payload, preventDefault) => {
      preventDefault()
      if (payload.event && payload.event.node.input) { // null guard
        const {
          context: {
            store
          },
          event: { node: { text, type, input: { name }, label } }
        } = payload

        const state = store.getState()
        const { input } = state

        const inputValue = input[name]
        const output = { key: name, value: inputValue }
        userInputAnalyticsEvent({ text, inputValue, name })
        userInputAnalytics({ name, inputValue })
        const { key, value } = parseUserInput({ type, label, output })
        if (value !== undefined) {
          try {
            const saveRef = scenarioPublicRef(uid)
            return saveRef.set(
              { [key]: userInputValueCheck(value) },
              {
                merge: true
              }
            )
          } catch (err) {
            // eslint-disable-next-line
            console.error('Error saving user input:', err)
            // eslint-disable-next-line
            console.error('Error saving user input (debug info):', { uid, key, value })
            Sentry.captureException(err)
          }
        }
      }
    }
  }
}
