<template>
  <v-container id="landing-page-generator">
    <v-alert
      v-if="!jobTypesValid"
      border="left"
      colored-border
      type="warning"
      elevation="2"
    >
      {{ $t("m.landing.updateJob_1") }}
      <v-btn outlined to="/bot-settings" class="mx-2">{{
        $t("m.botSettings.title")
      }}</v-btn>
      {{ $t("m.landing.updateJob_2") }}
    </v-alert>
    <v-card v-else>
      <v-progress-linear v-if="saving" indeterminate />
      <v-tabs v-model="tab">
        <v-tab>
          <template v-if="jobQuestionsMissingValue === false">
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
          </template>
          <template v-else>
            <v-icon color="warning">mdi-alert</v-icon>
          </template>
          {{ $t("m.landing.store.title") }}
        </v-tab>
        <v-tab>
          <template v-if="noMissingJobValues">
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
          </template>
          <template v-else>
            <v-icon color="warning">mdi-alert</v-icon>
          </template>
          {{ $t("m.landing.jobs.title") }}
        </v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item eager>
            <v-alert
              v-if="hasJobSite"
              border="top"
              colored-border
              type="success"
              elevation="2"
            >
              <div>{{ $t("m.landing.store.publicURL") }}</div>
              <div>
                <a target="_blank" :href="jobSiteUrl">{{ jobSiteUrl }}</a>
              </div>
            </v-alert>

            <v-alert
              v-if="hasPreview"
              border="top"
              colored-border
              type="info"
              elevation="2"
            >
              <div>{{ $t("m.landing.store.previewURL") }}</div>
              <div>
                <a target="_blank" :href="previewUrl">{{ previewUrl }}</a>
              </div>
            </v-alert>
            <FormMode
              v-if="pageUpil"
              :upil="pageUpil"
              :isMissingValue.sync="jobQuestionsMissingValue"
            :override="calculateComponent"
            />
          </v-tab-item>
          <v-tab-item eager>
            <v-row v-if="addJobType">
              <v-col cols="auto">
                <v-btn
                  small
                  outlined
                  color="green"
                  @click.stop="dialogOpen = true"
                >
                  <v-icon left>mdi-plus-circle-outline</v-icon>
                  {{ $t("m.landing.addJob") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-expansion-panels v-model="selectedJobTypeIndex" inset>
                  <v-expansion-panel
                    v-for="(jobUpil, jobType) in jobUpils"
                    :key="jobType"
                  >
                    <v-expansion-panel-header>
                      <v-row align="center">
                        <v-col cols="auto">
                          <template v-if="missingValues[jobType] === false">
                            <v-icon color="green"
                              >mdi-checkbox-marked-circle</v-icon
                            >
                          </template>
                          <template v-else>
                            <v-icon color="warning">mdi-alert</v-icon>
                          </template>
                        </v-col>
                        <v-col>{{
                          jobType === selectedJobType
                            ? botSettingsTitle
                            : jobType
                        }}</v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                      <v-row>
                        <v-col cols="12">
                          <v-alert
                            v-if="hasJobSite"
                            border="top"
                            colored-border
                            type="success"
                            elevation="2"
                          >
                            <div>
                              {{ jobType }}{{ $t("m.landing.jobs.publicURL") }}
                            </div>
                            <div>
                              <a
                                target="_blank"
                                :href="urls[jobTypes.indexOf(jobType)]"
                                >{{ urls[jobTypes.indexOf(jobType)] }}</a
                              >
                            </div>
                          </v-alert>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-alert
                            v-if="hasPreview"
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <div>
                              {{ jobType }}{{ $t("m.landing.jobs.previewURL") }}
                            </div>
                            <div>
                              <a
                                target="_blank"
                                :href="previewUrls[jobTypes.indexOf(jobType)]"
                                >{{ previewUrls[jobTypes.indexOf(jobType)] }}</a
                              >
                            </div>
                          </v-alert>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <FormMode
                            :key="`${jobType}-formbot`"
                            :upil="jobUpil"
                            @update:isMissingValue="missingValuesUpdated(jobType, $event)"
                            :override="calculateComponent"
                          />
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <div class="mt-3" v-if="jobTypesValid">
      <v-alert
        type="warning"
        colored-border
        border="left"
        tile
        elevation="2"
        v-if="hasMissingValues"
        >{{ $t("m.landing.notFilled") }}</v-alert
      >
      <v-btn
        @click="onClickSave({ preview: true })"
        outlined
        color="primary"
        :loading="saving"
        class="mr-2"
      >
        <v-icon left>mdi-content-save-alert</v-icon>
        {{ $t("m.landing.save") }}
      </v-btn>
      <v-btn
        @click="onClickSave({ preview: false })"
        outlined
        color="success"
        :loading="saving"
        v-if="hasPreview"
      >
        <v-icon left>mdi-cloud-upload</v-icon>
        {{ $t("m.landing.upload") }}
      </v-btn>
    </div>

    <v-dialog v-model="dialogOpen" max-width="290" :persistent="loading">
      <v-card>
        <v-card-title class="headline">{{
          $t("m.landing.addJob")
        }}</v-card-title>

        <v-card-text>
          <v-text-field
            :disabled="loading"
            v-model.trim="newJobType"
            :label="$t('m.landing.newJob')"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="grey"
            text
            @click="dialogOpen = false"
            :disabled="loading"
            >{{ $t("m.cancel") }}</v-btn
          >

          <v-btn
            color="primary"
            @click="onAddJobType"
            :loading="loading"
            :disabled="loading || !newJobTypeValid"
            >{{ $t("m.add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { FormMode } from '@appsocially/vue-upil'
import { UPILCore } from '@appsocially/userpil-core'
import { shrinkUrl } from '@/helpers/referral'
import { mapState } from 'vuex'
import { firestoreOutgoingDataCleaner } from '@/helpers/upil'

export function calculateComponent ({ type, label }, defaultComponent) {
  switch (label) {
    case 'numerical':
      return () => import('./numerical')
    default:
      return defaultComponent
  }
}

function cleanState (upil) {
  const stateInput = upil.UpilStore.getState().input
  const strippedPairs = Object.entries(stateInput).filter(([key, value]) => value !== upil.symbols.UNRESOLVED)
  const noUnresolveds = Object.fromEntries(strippedPairs)
  const cleaned = firestoreOutgoingDataCleaner(noUnresolveds)
  return cleaned
}

function setupListeners ({ listeners, upil }) {
  const unsubscribeArray = Object.keys(listeners).map(event => {
    const handler = listeners[event]
    return upil.on(event, handler)
  })

  return unsubscribeArray
}

export default {
  components: {
    FormMode
  },
  props: {
    hasJobSite: {
      type: Boolean,
      default: false
    },
    hasPreview: {
      type: Boolean,
      default: false
    },
    jobTypes: {
      type: Array,
      required: true
    },
    addJobType: {
      type: Function,
      required: false
    },
    pageScriptListeners: {
      type: Function,
      required: true
    },
    jobScriptListeners: {
      type: Function,
      required: true
    },
    pageScript: {
      type: String,
      required: true
    },
    jobScript: {
      type: String,
      required: true
    },
    onSave: {
      type: Function,
      required: true
    },
    onPreview: {
      type: Function,
      required: true
    },
    baseStaticSiteUrl: {
      type: String,
      default: 'https://static-site-generation-dev-kaoruhosokawa.appsocially.now.sh'
    }
  },
  data: () => ({
    dialogOpen: false,
    loading: false,
    newJobType: '',
    pageUpil: null,
    jobUpils: {},
    activeUpil: null,
    missingValues: {},
    jobQuestionsMissingValue: false,
    saving: false,
    selectedJobTypeIndex: -1,
    tab: -1,
    jobSiteUrl: '',
    previewUrl: '',
    urls: [],
    previewUrls: [],
    calculateComponent
  }),
  computed: {
    ...mapState('auth', ['uid']),
    newJobTypeValid () {
      return this.newJobType !== ''
    },
    selectedJobType () {
      return this.jobTypes[this.selectedJobTypeIndex]
    },
    botSettingsTitle () {
      return this.selectedJobType ? `${this.selectedJobType}${this.$i18n.t('m.landing.jobs.pageOf')}` : null
    },
    noMissingJobValues () {
      return this.jobTypesValid ? this.jobTypes.every(jobType => this.missingValues[jobType] === false) : true
    },
    hasMissingValues () {
      return !(this.noMissingJobValues && this.jobQuestionsMissingValue === false)
    },
    // If jobTypes is using the old string-based method, then the owner will need to update their entry
    jobTypesValid () {
      return Array.isArray(this.jobTypes)
    }
  },
  watch: {
    pageScript: {
      immediate: true,
      handler (pageScript) {
        this.setupPageUpil()
      }
    },
    jobTypes: {
      immediate: true,
      handler (jobTypes) {
        this.setupJobUpils(jobTypes)
      }
    }
  },
  methods: {
    async generatePageUrl (jobType) {
      const index = this.jobTypes.indexOf(jobType)
      return shrinkUrl(`${this.baseStaticSiteUrl}/pages/${this.uid}/${index + 1}`)
    },
    async generatePreviewPageUrl (jobType) {
      const index = this.jobTypes.indexOf(jobType)
      return shrinkUrl(`${this.baseStaticSiteUrl}/previews/${this.uid}/latest/${index + 1}`)
    },
    async onAddJobType () {
      this.loading = true
      try {
        if (this.newJobTypeValid) {
          await this.addJobType(this.newJobType)
          this.newJobType = ''
          this.dialogOpen = false
        }
      } finally {
        this.loading = false
      }
    },
    setupPageUpil () {
      const upil = new UPILCore()

      setupListeners({ listeners: this.pageScriptListeners(), upil })
      upil.startRaw(this.pageScript, {
        mode: 'form',
        resetOnInputUpdate: true
      })
      this.pageUpil = upil
    },
    setupJobUpils (jobTypes) {
      if (this.jobTypesValid) {
        jobTypes.forEach(jobType => {
          this.setupJobUpil(jobType)
        })
      }
    },
    setupJobUpil (jobType) {
      const hasUpil = this.jobUpils[jobType]
      if (!hasUpil) {
        const upil = new UPILCore()

        // Setup formode listeners
        setupListeners({ listeners: this.jobScriptListeners(jobType), upil })

        upil.startRaw(this.jobScript, {
          mode: 'form',
          resetOnInputUpdate: true
        })

        this.$set(this.jobUpils, jobType, upil)
      }
    },
    generatePageJobData () {
      const { settings, ...pageDetails } = this.pageUpil ? cleanState(this.pageUpil) : {}
      const jobsData = Object.entries(this.jobUpils)
        .map(([jobType, upilInstance]) => ({
          jobType,
          data: cleanState(upilInstance)
        }))
        .map(({ jobType, data: { settings, ...data } }) => ({
          jobType,
          data
        }))
      return { pageDetails, jobsData }
    },
    async onClickSave ({ preview }) {
      if (!this.saving && !this.loading) {
        this.saving = true
        try {
          const data = this.generatePageJobData()
          await preview ? this.onPreview(data) : this.onSave(data)
        } finally {
          this.saving = false
        }
      }
    },
    missingValuesUpdated (jobType, isMissingValue) {
      this.$set(this.missingValues, jobType, isMissingValue)
    }
  },
  async mounted () {
    this.jobSiteUrl = await shrinkUrl(`${this.baseStaticSiteUrl}/pages/${this.uid}`)
    this.previewUrl = await shrinkUrl(`${this.baseStaticSiteUrl}/previews/${this.uid}/latest`)
    this.urls = await Promise.all(this.jobTypes.map(jobType => { return this.generatePageUrl(jobType) }))
    this.previewUrls = await Promise.all(this.jobTypes.map(jobType => { return this.generatePreviewPageUrl(jobType) }))
  }
}
</script>
